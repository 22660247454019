<template>
    <div>
        <div class="home-banner-bg">
            <div>
                <BannerTitleItem></BannerTitleItem>
            </div>
            <div class="bottomTab">
            </div>
        </div>
        <div style="display: flex;justify-content: center;">
            <div style="width: 1520px;">
                <div style="text-align: left;margin-top: 20px;font-size: 16px;">
                    <el-breadcrumb separator="/" style="padding-bottom: 20px;">
                        <el-breadcrumb-item :to="{ path: '/enterprise/home' }" style="color: #FFFFFF;">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/enterprise/dynamicList' }">产品动态</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>

                <div
                    style="margin-top: 40px;font-size: 20px;font-weight: bold;padding-bottom: 20px;border-bottom: #DCE5F2 solid 1px;margin-bottom: 40px;">
                    产品动态</div>



                <div style="display: flex;align-items: baseline;">
                    <div>
                        <div>
                            <div style="margin-right: 20px;" class="reset" :class="typeIndex===index&&'sure'"
                                @click="selectType({item,index})" v-for="(item,index) in typeList" :key="index">
                                {{item.name}}</div>
                        </div>
                    </div>

                    <div style="flex: 1;">
                        <div style="margin-top: 40px;" v-loading="loadingStatus">
                            <div style="margin-bottom: 20px;" v-for="(item,index) in listData" :key="index"
                                @click="goDetail({item})">
                                <div style="background:#FBFBFB ;padding: 20px 20px 20px 40px;text-align: left;">
                                    <div style="display: flex;justify-content: space-between;align-items: center;">
                                        <div style="flex: 1;">
                                            <div style="font-size: 16px;font-weight: bold;max-width: 600px;" class="">
                                                {{item.informationTitle}}</div>
                                            <div style="font-size: 14px;color: #888888;margin-top: 20px;" class="line1">
                                                {{item.createTime}}</div>
                                        </div>
                                       <i class="el-icon el-icon-arrow-right" style="flex-shrink: 0;font-weight: bold;font-size: 24px;"></i>
                                    </div>

                                </div>
                            </div>

                            <div v-if="listData.length" style="text-align: right; margin-top: 20px;margin-bottom: 20px;">
                                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                    :current-page.sync="currentPage" :page-sizes="[10, 20, 30, 50, 100]"
                                    :page-size="size" layout="total, sizes, prev, pager, next, jumper" :total="total">
                                </el-pagination>
                            </div>

                        </div>

                        <el-empty v-if="!listData.length" description="数据为空"></el-empty>
                    </div>

                </div>


            </div>
        </div>


    </div>

</template>

<script>
    import { industryInformation, getCommonColumnChild } from "@/api/bid.js"
    import BannerTitleItem from '../components/banner-title-item/index.vue'
    export default {
        components: {
            BannerTitleItem
        },
        data() {
            return {
                typeList: [],
                typeIndex: 0,
                className:'',
                loadingStatus: false,
                listData: [],
                total: 0,
                currentPage: 1,
                size: 10,
            };
        },

        async created() {

            this.getCommonTags()
            this.getListData()
        },
        mounted() {

        },
        methods: {
            goDetail({ item }) {
                this.$router.push({
                    path: '/enterprise/dynamicDetail',
                    query: { informationId: item.informationId, fromPage: 'sercher' },
                });
            },
          
            selectType({ item, index }) {
                if (this.typeIndex === index) return
                this.typeIndex = index
                this.className = item.name==='全部'? '': item.name
                this.listData = []
                this.getListData()

                console.log('======', item.industryInformationClassId)
            },
            selectHot({ item, index }) {
                this.hotIndex = index
                this.tags = item.name
                this.listData = []
                this.getListData()
            },
            async getCommonTags() {
                try {
                    let params = {
                        appid: "wxbd94b37dd45c1f91",
                        type: 'PcProductTab'
                    }
                    let { data } = await getCommonColumnChild(params)
                    this.typeList = [{name:'全部'}].concat(data[0]?.child||[] )

                    console.log('===this.typeList', this.typeList)
                } catch (error) {

                }
            },

            handleSizeChange(val) {

                this.size = val
                this.getListData();
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this.getListData();
            },
            //开始查询
            async getListData() {
                try {

                    let params = {
                        current: this.currentPage,
                        size: this.size,
                        className:this.className,
                        infoType: 'productDynamics',
                    }
                    this.loadingStatus = true
                    let { data } = await industryInformation(params)

                    data.records.forEach(item => {
                        item.tagsList = []
                        if (item.tags) {
                            let list = item.tags.split(',')
                            item.tagsList = list
                        }
                    });
                    this.loadingStatus = false
                    this.listData = data.records
                    this.total = data.total
                } catch (error) {
                    this.loadingStatus = false
                    console.log('=======error查询异常', error)

                }
            },

        },
    };
</script>

<style scoped>
    .line2 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* 设置最大显示行数 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;

    }

    .line1 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        /* 设置最大显示行数 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;

    }



    .reset {
        text-align: left;
        padding: 12px 0 12px 30px;
        font-size: 16px;
        cursor: pointer;
        width: 140px;

    }

    .sure {
        border-left: solid 4px #007EF2;

        font-size: 16px;
        cursor: pointer;
        background: #F8F8F8;
    }
</style>